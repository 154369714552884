<template>
  <v-container fluid class="pa-0">
    <v-tabs v-model="tab">
      <v-tab key="cashier">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ $t("generic.lang_kasse") }}
        </div>
      </v-tab>
      <v-tab key="system-info">
        {{ $t("settings.lang_systemInfo") }}
      </v-tab>
      <v-tab key="subdomain-generator"
        v-if="$store.getters['permissions/checkModule'](39) || $store.getters['permissions/checkModule'](41) || $store.getters['permissions/checkModule'](54)  || $store.getters['permissions/checkModule'](55) || $store.getters['permissions/checkModule'](56) || $store.getters['permissions/checkModule'](62)">
        {{ $t("settings.lang_subdomainsGenerator") }}
      </v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item key="cashier" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">
          <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="main-card mb-3 card pa-0 ma-0 shadow-none">
            <div class="card-body pa-0">
              <v-divider class="pa-0 ma-0" />
              <div>
                <v-progress-linear class="ma-0" color="primary" indeterminate v-if="this.loading"></v-progress-linear>
              </div>
              <v-card-text>
                <v-row >
                  <v-col cols="12" md="6"><v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading" hide-details
                      :label="$t('generic.lang_POSNAME')" @focus="showTouchKeyboard" autocomplete="off" dense outlined
                      required v-model="posName"></v-text-field></v-col>
                  <v-col cols="12" md="6"><v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading" hide-details
                      :label="$t('generic.lang_taxId')" @focus="showTouchKeyboard" autocomplete="off" dense outlined
                      required v-model="taxID">
                    <template v-slot:append-outer>
                        <v-tooltip top nudge-left="100">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
                          </template>
                          <span>
                            {{ $t("settings.lang_taxIdExample") }}
                          </span>
                        </v-tooltip>
                      </template>
                    </v-text-field></v-col>
                  <v-col cols="12" md="6"><v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading" hide-details
                      :label="$t('generic.lang_taxNumber')" @focus="showTouchKeyboard" autocomplete="off" dense outlined
                      required v-model="taxNumber">
                    <template v-slot:append-outer>
                        <v-tooltip top nudge-left="100">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
                          </template>
                          <span>
                            {{ $t("settings.lang_taxNumberExample") }}
                          </span>
                        </v-tooltip>
                      </template>
                    </v-text-field></v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field hide-details  :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading" :label="
                        $t('generic.lang_street') +
                          ' ' +
                          $t('generic.lang_and') +
                          ' ' +
                          $t('customers.lang_number')
                      " @focus="showTouchKeyboard" autocomplete="off" dense outlined required
                      v-model="streetNo"></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field hide-details  :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                      :label="$t('generic.lang_zip')" @focus="showTouchKeyboard" autocomplete="off" dense outlined
                      required v-model="zip"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-text-field hide-details  :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                      :label="$t('accounting.lang_set_currency')" @focus="showTouchKeyboard" autocomplete="off" dense
                      outlined required v-model="currency"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field hide-details  :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                      :label="$t('settings.lang_city')" @focus="showTouchKeyboard" autocomplete="off" dense outlined
                      required v-model="city"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-select hide-details :disabled="this.loading" :items="countries" :label="$t('generic.lang_country')" dense
                      item-text="label" item-value="value" outlined v-model="country"></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field hide-details  :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                      :label="$t('generic.lang_phoneNumber')" @focus="showTouchKeyboard" autocomplete="off" dense
                      outlined required v-model="telephone"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field hide-details  :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                      :label="$t('generic.lang_fax')" @focus="showTouchKeyboard" autocomplete="off" dense outlined
                      required v-model="fax"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field hide-details  :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                      :label="$t('generic.lang_emailAdresse')" @focus="showTouchKeyboard" autocomplete="off" dense
                      outlined required v-model="email"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field hide-details  :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                      :label="$t('generic.lang_Website')" @focus="showTouchKeyboard" autocomplete="off" dense outlined
                      required v-model="website"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="pa-0 ma-0" />
              <div :class="[this.$vuetify.theme.dark? 'dark-bg' : 'white']"
                class="card-title card-header-tab card-header">
                <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                  {{ $t("generic.lang_ansprechpartner") }}
                </div>
                <div class="btn-actions-pane-right actions-icon-btn">
                  <div class="btn-actions-pane-right actions-icon-btn"></div>
                </div>
              </div>
              <v-divider class="pa-0 ma-0" />
              <v-card-text>
                <v-layout>
                  <v-flex md6 ml-2 mr-2>
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                      :label="$t('customers.lang_cust_prename')" @focus="showTouchKeyboard" autocomplete="off" dense
                      outlined required v-model="contactPerson.name"></v-text-field>
                  </v-flex>

                  <v-flex md6 ml-2 mr-2>
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                      :label="$t('customers.lang_cust_name')" @focus="showTouchKeyboard" autocomplete="off" dense
                      outlined required v-model="contactPerson.lastName"></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout>
                  <v-flex md6 ml-2 mr-2>
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                      :label="$t('generic.lang_emailAdresse')" @focus="showTouchKeyboard" autocomplete="off" dense
                      outlined required v-model="contactPerson.email"></v-text-field>
                  </v-flex>

                  <v-flex md6 ml-2 mr-2>
                    <v-select :disabled="this.loading" :items="salutations" :label="$t('customers.lang_cust_anrede')"
                      dense item-text="name" item-value="name" outlined v-model="contactPerson.salutation"></v-select>
                  </v-flex>
                </v-layout>

                <v-layout>
                  <v-flex md6 ml-2 mr-2>
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                      :label="$t('customers.lang_cust_mobil_business')" @focus="showTouchKeyboard" autocomplete="off"
                      dense outlined required v-model="contactPerson.mobile"></v-text-field>
                  </v-flex>

                  <v-flex md6 ml-2 mr-2>
                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.loading"
                      :label="$t('generic.lang_phoneNumber')" @focus="showTouchKeyboard" autocomplete="off" dense
                      outlined required v-model="contactPerson.telephone"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-card-text>

              <div :class="[this.$vuetify.theme.dark? 'dark-bg' : 'white']" class="card-footer text-right pa-0">
                <v-spacer />
                <v-btn :disabled="this.loadUpdate || this.loading" :loading="this.loadUpdate" @click="updateData"
                  class="mr-5 btn btn-primary" color="primary">
                  {{ this.$t("generic.lang_save") }}
                </v-btn>
              </div>
            </div>

            <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
              <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                :options="touchKeyboard.options" class="internalWidth" id="onScreenKeyboard"
                v-if="touchKeyboard.visible" />
            </div>
          </div>
        </v-tab-item>

        <v-tab-item key="system-info">
          <v-card elevation="0">
            <v-card-text class="pa-0">
              <v-col class="pa-0 ma-0" cols="12">
                <v-form lazy-validation ref="form">
                  <v-row class="pa-0 ma-0">
                    <v-col class="" cols="12">
                      <v-subheader>
                        <strong>{{ $t('settings.lang_systemInfoMsg') }}</strong>
                      </v-subheader>
                      <quill-editor id="editor" class="mb-16" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        @focus="showTouchKeyboard" :options="quillOptions" output="html" v-model="systemInfo">
                      </quill-editor>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-card-text>
            <v-card-actions class="card-footer text-right pt-1 pb-1" background-color="white">
              <v-spacer />
              <v-btn :disabled="loading" :loading="loading" @click="updateSystemInfo()"
                class="bg-success text-light mx-1">
                {{ $t("generic.lang_update") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>

        <v-tab-item key="subdomain-generator"
          v-if="$store.getters['permissions/checkModule'](39) || $store.getters['permissions/checkModule'](41) || $store.getters['permissions/checkModule'](54)  || $store.getters['permissions/checkModule'](55) || $store.getters['permissions/checkModule'](56) || $store.getters['permissions/checkModule'](62)">

          <subdomain-generator-component />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {quillEditor} from "vue-quill-editor";
import {Events} from "@/plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import SubdomainGeneratorComponent from "@/components/settings/sysetmdata/cashier/SubdomainGeneratorComponent";
const countries = require('i18n-iso-countries');
countries.registerLocale(require("i18n-iso-countries/langs/de.json"));
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
countries.registerLocale(require("i18n-iso-countries/langs/ar.json"));

export default {
  components: {SubdomainGeneratorComponent,quillEditor},
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      tab: 0,
      posName: null,
      taxID: null,
      taxNumber: null,
      streetNo: null,
      zip: null,
      city: null,
      loadUpdate: false,
      country: null,
      currency: null,
      telephone: null,
      fax: null,
      email: null,
      website: null,
      contactPerson: {
        salutation: null,
        name: null,
        lastName: null,
        email: null,
        telephone: null,
        mobile: null,
      },
      loading: false,
      salutations: [
        {name: this.$t("generic.lang_gender_m")},
        {name: this.$t("generic.lang_gender_w")},
      ],
      // itemCountries: [
      //   {name: this.$t("generic.lang_greatBritain"), id: "english"},
      //   {name: this.$t("generic.lang_germany"), id: "germany"},
      //   {name: this.$t("generic.lang_france"), id: "france"},
      //   {name: this.$t("generic.lang_swiss"), id: "swiss"},
      // ],
      quillOptions: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            ["code-block"],
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            [
              {list: "ordered"},
              {list: "bullet"},
              {indent: "-1"},
              {indent: "+1"},
            ],
            [{align: []}],
            [{color: []}, {background: []}],
            [{font: []}],
            ["clean"],
            ["link"],
            ["image", "video"],
          ],
        },
      },
      systemInfo: null,
      // ----------------------------------
    };
  },
  computed: {
    countries () {
      const list = countries.getNames(this.$i18n.locale, { select: 'official' });
      return Object.keys(list).map((key) => ({ value: key.toLowerCase(), label: list[key] }));
    }
  },
  methods: {
    getData() {
      let self = this;
      this.loading = true;
      this.axios
          .post(ENDPOINTS.SETTINGS.BASIC.GET, {})
          .then((res) => {
            if (res.status === 200) {
              (this.posName =
                  res.data.formfillData.textFields.settings_SystemName),
                  (this.taxID = res.data.formfillData.textFields.settings_TaxID),
                  (this.streetNo =
                      res.data.formfillData.textFields.settings_streetAndNumber),
                  (this.zip = res.data.formfillData.textFields.settings_zip),
                  (this.city = res.data.formfillData.textFields.settings_city),
                  (this.country =
                      res.data.formfillData.textFields.settings_country);
              this.currency = res.data.formfillData.textFields.currencySign;
              this.taxNumber = res.data.formfillData.textFields.settings_TaxNumber;
              this.telephone = res.data.formfillData.textFields.systemPhoneNo;
              this.fax = res.data.formfillData.textFields.systemFaxNo;
              this.email = res.data.formfillData.textFields.systemEmail;
              this.website = res.data.formfillData.textFields.systemWebsite;

              this.contactPerson.email =
                  res.data.formfillData.textFields.systemContactEmail;
              this.contactPerson.salutation =
                  res.data.formfillData.textFields.systemContactSalutation;
              this.contactPerson.name =
                  res.data.formfillData.textFields.systemContactPreName;
              this.contactPerson.lastName =
                  res.data.formfillData.textFields.systemContactLastName;
              this.contactPerson.mobile =
                  res.data.formfillData.textFields.systemContactMobileNo;
              this.contactPerson.telephone =
                  res.data.formfillData.textFields.systemContactPhoneNo;
            } else {
              Events.$emit("showSnackbar", {
                message: self.$t("generic.lang_errorOccurred"),
                color: "error",
              });
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              message: self.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    updateData: function () {
      this.loadUpdate = true;
      this.axios
          .post(ENDPOINTS.SETTINGS.BASIC.UPDATE, {
            settings_SystemName: this.posName,
            settings_TaxID: this.taxID,
            settings_TaxNumber: this.taxNumber,
            settings_streetAndNumber: this.streetNo,
            settings_zip: this.zip,
            currencySign: this.currency,
            settings_city: this.city,
            settings_country: this.country,
            systemPhoneNo: this.telephone,
            systemFaxNo: this.fax,
            systemEmail: this.email,
            systemWebsite: this.website,
            systemContactEmail: this.contactPerson.email,
            systemContactSalutation: this.contactPerson.salutation,
            systemContactPreName: this.contactPerson.name,
            systemContactLastName: this.contactPerson.lastName,
            systemContactMobileNo: this.contactPerson.mobile,
            systemContactPhoneNo: this.contactPerson.telephone,
          })
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_actionSuccessful"),
                color: "success",
              });
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_errorOccurred"),
                color: "error",
              });
            }
          })
          .catch(() => {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          })
          .finally(() => {
            this.loadUpdate = false;
          });
    },

    updateSystemInfo() {
      this.loading = true;

      this.axios
          .post({})
          .then((res) => {
            if (res.data.STATUS === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_updatedSuccessfully"),
                color: "success",
              });
            } else {
              Events.$emit("showSnackbar", {
                message: res.data.msg || res.data.STATUS,
                color: "error",
              });
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred") + " " + err.message,
              color: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
  mounted() {
    //GET DATA
    this.getData();
  },
};
</script>

<style scoped>
.quill-editor {
  height: 300px !important;
}
</style>