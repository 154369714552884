<template>
    <div>
        <v-card flat tile class="ma-0">
            <v-form v-model="valid" ref="form">
                <v-container>
                    <v-row>
                        <v-col md="6" sm="12" cols="12">
                            <v-text-field outlined
                                          @focus="showTouchKeyboard"
                                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          v-model="invoiceAccountOwner"
                                          :label="$t('settings.lang_accountOwner')"
                                          autocomplete="off"
                                          dense
                                          hide-details
                            />
                        </v-col>
                        <v-col md="6" sm="12" cols="12">
                            <v-text-field outlined
                                          @focus="showTouchKeyboard"
                                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          v-model="invoiceIban"
                                          label="IBAN"
                                          autocomplete="off"
                                          dense
                                          hide-details
                            />
                        </v-col>
                        <v-col md="6" sm="12" cols="12">
                            <v-text-field outlined
                                          @focus="showTouchKeyboard"
                                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          v-model="invoiceBic"
                                          label="BIC"
                                          autocomplete="off"
                                          dense
                                          hide-details
                            />
                        </v-col>
                        <v-col lg="8" md="6" sm="12" cols="12">
                            <v-textarea outlined
                                        @focus="showTouchKeyboard"
                                        :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                        v-model="freeText"
                                        :label="$t('settings.lang_bankTransferFreetext')"
                                        autocomplete="off"
                                        dense
                            >

                            </v-textarea>
                        </v-col>
                        <v-col lg="4" md="6" sm="12" cols="12">
                            <v-text-field outlined
                                          @focus="showTouchKeyboard"
                                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          v-model="period"
                                          :label="this.$t('accounting.lang_paymentTermsInDays')"
                                          autocomplete="off"
                                          type="number"
                                          dense
                            />
                        </v-col>

                        <v-col lg="4" md="6" sm="12" cols="12">
                            <v-checkbox v-model="enabled" value="1" :label="this.$t('accounting.lang_paymentOptBillOnlyIfCustomerChoosen')"></v-checkbox>
                        </v-col>
                        <v-col lg="4" md="6" sm="12" cols="12">
                            <v-checkbox v-model="allowed" value="1" :label="this.$t('accounting.lang_paymentOptIfCustomerAllowed4Bill')"></v-checkbox>
                        </v-col>

                      <v-col lg="8" md="6" sm="12" cols="12">
                        <v-textarea outlined
                                    @focus="showTouchKeyboard"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    v-model="freeTextDirectDebit"
                                    :label="$t('settings.lang_directDebitFreetext')"
                                    autocomplete="off"
                                    dense
                        >

                        </v-textarea>
                      </v-col>
                      <v-col lg="8" md="8" sm="12" xl="8" cols="12">
                        <v-textarea outlined
                                    @focus="showTouchKeyboard"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    v-model="invoicePaymentRecieptFreeText"
                                    :label="$t('settings.lang_invoiceNoteOnReceipt')"
                                    autocomplete="off"
                                    dense
                        >

                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-divider class="ma-0 pa-0"/>
                    <v-row no-gutters justify-md="end" justify="center">
                        <v-btn color="success" large :loading="loading" :disabled="!valid" @click="update()">
                          {{$t('generic.lang_apply')}}
                        </v-btn>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                :defaultKeySet="touchKeyboard.keySet"
                                :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                :options="touchKeyboard.options" class="internalWidthExpanded"
                                id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
        </div>
    </div>
</template>

<script>
    import mixin from '../../../../mixins/KeyboardMixIns'
    import {Events} from "../../../../plugins/events";

    export default {
        name: "InvoicesComponent",
        components:{},
        mixins:[mixin],
        data(){
            return{
                valid:false,
                loading:false,
                enabled:false,
                allowed:false,
                freeText:null,
                freeTextDirectDebit:null,
                invoiceAccountOwner:null,
                invoiceIban:null,
                invoiceBic:null,
              invoicePaymentRecieptFreeText:null,
                period:0,
            }
        },
        methods:{
            getData(){
                this.loading=true;
                this.axios.post('get/settings/updateInvoicePaymentSettings/',{})
                    .then(res=>{

                        if(res.data.status==='SUCCESS'){
                            this.enabled=res.data.invoiceOnlyIfACustomerSelected
                            this.allowed=res.data.invoiceOnlyIfUnlockedForInvoices
                            this.freeText=res.data.invoiceFreeText
                            this.period=res.data.payableWithinDays
                            this.freeTextDirectDebit=res.data.freeTextDirectDebit
                            this.invoicePaymentRecieptFreeText=res.data.invoicePaymentRecieptFreeText
                            this.invoiceAccountOwner=res.data.invoiceAccountOwner
                            this.invoiceIban=res.data.invoiceIban
                            this.invoiceBic=res.data.invoiceBic
                        }
                        this.loading=false;
                }).catch(err=>{
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_error'),
                        color: "error"
                    });
                    this.loading=false;
                });
            },
            update(){
                this.loading=true;
                this.axios.post('update/settings/updateInvoicePaymentSettings/',{
                    invoiceOnlyIfACustomerSelected:this.enabled,
                    invoiceOnlyIfUnlockedForInvoices:this.allowed,
                    invoiceFreeText:this.freeText,
                    payableWithinDays:this.period,
                    freeTextDirectDebit:this.freeTextDirectDebit,
                  invoicePaymentRecieptFreeText:this.invoicePaymentRecieptFreeText,
                  invoiceAccountOwner:this.invoiceAccountOwner,
                  invoiceIban:this.invoiceIban,
                  invoiceBic:this.invoiceBic,
                }).then(res=>{
                    if(res.data.status==='SUCCESS')
                    {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_updatedSuccessfully'),
                            color: "success"
                        });
                    }else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_error'),
                            color: "error"
                        });
                    }
                        this.loading=false;
                    }).catch(err=>{

                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_error'),
                        color: "success"
                    });
                        this.loading=false;
                });
            }
        },
        mounted() {
            this.getData();
        }
    }
</script>

<style scoped>

</style>
